@use 'lib/styles/defs/breakpoints';

.page:not(.home-page) > main > .root > .aem-Grid > .responsivegrid,
.page:not(.home-page) > .root > .aem-Grid > .responsivegrid {
	max-width: 1380px;
	margin: 0px auto;
	float: none;

	padding-left: 18px;
	padding-right: 18px;
	@media screen and (min-width: breakpoints.$breakpoint-md) {
		.aem-GridColumn {
			padding-left: 8px;
			padding-right: 8px;
		}
		padding-left: 12px;
		padding-right: 12px;
	}
	@media screen and (min-width: breakpoints.$breakpoint-lg) {
		.aem-GridColumn {
			padding-left: 12px;
			padding-right: 12px;
		}
		padding-left: 26px;
		padding-right: 26px;
	}
	@media screen and (min-width: breakpoints.$breakpoint-xl) {
		padding-left: 28px;
		padding-right: 28px;
	}
	@media screen and (min-width: breakpoints.$breakpoint-ul) {
		padding-left: 0;
		padding-right: 0;
	}
}
.xf-web-container > .xp-fragment-container > .root.responsivegrid {
	max-width: 1380px;
	margin: 0px auto;
	float: none;

	padding-left: 18px;
	padding-right: 18px;
	.aem-GridColumn {
		padding-bottom: 0;
	}
	@media screen and (min-width: breakpoints.$breakpoint-md) {
		.aem-GridColumn {
			padding-left: 8px;
			padding-right: 8px;
		}
		padding-left: 12px;
		padding-right: 12px;
	}
	@media screen and (min-width: breakpoints.$breakpoint-lg) {
		.aem-GridColumn {
			padding-left: 12px;
			padding-right: 12px;
		}
		padding-left: 26px;
		padding-right: 26px;
	}
	@media screen and (min-width: breakpoints.$breakpoint-xl) {
		padding-left: 28px;
		padding-right: 28px;
	}
	@media screen and (min-width: breakpoints.$breakpoint-ul) {
		padding-left: 0;
		padding-right: 0;
	}
}
