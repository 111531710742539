@charset "UTF-8";
.height-1 {
  height: 8px;
}

.height-2 {
  height: 16px;
}

.height-3 {
  height: 24px;
}

.height-4 {
  height: 32px;
}

.height-5 {
  height: 40px;
}

.height-6 {
  height: 48px;
}

.height-7 {
  height: 56px;
}

.height-8 {
  height: 64px;
}

.height-9 {
  height: 72px;
}

.height-10 {
  height: 80px;
}

.height-11 {
  height: 88px;
}

.height-12 {
  height: 96px;
}

.height-13 {
  height: 104px;
}

.height-14 {
  height: 112px;
}

.height-15 {
  height: 120px;
}

.height-16 {
  height: 128px;
}

.height-17 {
  height: 136px;
}

.height-18 {
  height: 144px;
}

.height-19 {
  height: 152px;
}

.height-20 {
  height: 160px;
}

:root,
.has-light-background {
  --text-color: var(--sdx-color-gray);
  --headline-color: var(--sdx-color-sc-navy);
  --int-color: var(--sdx-color-int-blue);
  --int-color--active: var(--sdx-color-int-blue--active);
  color: var(--text-color);
}

.has-dark-background {
  --text-color: var(--sdx-color-sc-white);
  --headline-color: var(--sdx-color-sc-white);
  --int-color: var(--sdx-color-sc-white);
  --int-color--active: var(--sdx-color-sc-white);
  color: var(--text-color);
}

html {
  scroll-behavior: smooth;
  quotes: "«" "»" "‹" "›";
}
html:lang(de) {
  quotes: "«" "»" "‹" "›" "„" "“" "‚" "‘";
}
html:lang(en) {
  quotes: "“" "”" "‘" "’";
}

body {
  overflow-x: hidden;
  overflow-y: scroll;
}

body.xf-web-container {
  overflow-y: auto;
}

.pagemetadata {
  display: none;
}

.reset-paddings.padding-top-1, .reset-paddings.padding-top-2, .reset-paddings.padding-top-3, .reset-paddings.padding-top-4 {
  padding-top: 0 !important;
}
.reset-paddings.padding-bottom-1, .reset-paddings.padding-bottom-2, .reset-paddings.padding-bottom-3, .reset-paddings.padding-bottom-4 {
  padding-bottom: 0 !important;
}
.reset-paddings.padding-left-1, .reset-paddings.padding-left-2, .reset-paddings.padding-left-3, .reset-paddings.padding-left-4 {
  padding-left: 4px !important;
}
@media screen and (min-width: 768px) {
  .reset-paddings.padding-left-1, .reset-paddings.padding-left-2, .reset-paddings.padding-left-3, .reset-paddings.padding-left-4 {
    padding-left: 8px !important;
  }
}
@media screen and (min-width: 1024px) {
  .reset-paddings.padding-left-1, .reset-paddings.padding-left-2, .reset-paddings.padding-left-3, .reset-paddings.padding-left-4 {
    padding-left: 12px !important;
  }
}
.reset-paddings.padding-right-1, .reset-paddings.padding-right-2, .reset-paddings.padding-right-3, .reset-paddings.padding-right-4 {
  padding-right: 4px !important;
}
@media screen and (min-width: 768px) {
  .reset-paddings.padding-right-1, .reset-paddings.padding-right-2, .reset-paddings.padding-right-3, .reset-paddings.padding-right-4 {
    padding-right: 8px !important;
  }
}
@media screen and (min-width: 1024px) {
  .reset-paddings.padding-right-1, .reset-paddings.padding-right-2, .reset-paddings.padding-right-3, .reset-paddings.padding-right-4 {
    padding-right: 12px !important;
  }
}

table.table-text-wrap {
  white-space: normal;
  word-break: break-word;
}

body[data-wcm-mode=DISABLED] .aem-functional-div {
  display: contents;
}

@media screen and (max-width: 767px) {
  .margin-top-override,
  .reset-paddings.margin-top-override {
    margin-top: 8px !important;
  }
  .margin-bottom-override,
  .reset-paddings.margin-bottom-override {
    margin-bottom: 8px !important;
  }
  .margin-left-override,
  .reset-paddings.margin-left-override {
    margin-left: 0 !important;
  }
  .margin-right-override,
  .reset-paddings.margin-right-override {
    margin-right: 0 !important;
  }
  .padding-top-override,
  .reset-paddings.padding-top-override {
    padding-top: 0 !important;
  }
  .padding-bottom-override,
  .reset-paddings.padding-bottom-override {
    padding-bottom: 0 !important;
  }
  .padding-left-override,
  .reset-paddings.padding-left-override {
    padding-left: 4px !important;
  }
  .padding-right-override,
  .reset-paddings.padding-right-override {
    padding-right: 4px !important;
  }
}
.col-xs-has-aspect-ratio:before {
  content: "";
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
}

.col-xs-has-aspect-ratio:after {
  content: "";
  display: table;
  clear: both;
}

.col-xs-has-aspect-ratio > div {
  width: 100%;
}

/*New*/
.row.has-bg {
  position: relative;
}
.row.has-bg .bg {
  position: relative;
  min-width: 100%;
  min-height: 100%;
  left: 0;
  right: 0;
  overflow: hidden;
}
@media (min-width: 0) {
  .row.has-bg .bg.full-width {
    left: 50%;
    right: 50%;
  }
}
@media (min-width: 768px) {
  .row.has-bg .bg.full-width {
    left: calc(50% - 10px);
    right: calc(50% - 10px);
  }
}
.row.has-bg .bg.bg--image-mode-natural img {
  position: absolute;
  margin: auto;
}
.row.has-bg .bg.bg--image-mode-cover img {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  margin: auto;
}
.row.has-bg .bg.bg--v-top img {
  top: 0;
  bottom: auto;
}
.row.has-bg .bg.bg--v-middle img {
  top: -3000px;
  bottom: -3000px;
}
.row.has-bg .bg.bg--v-bottom img {
  top: auto;
  bottom: 0;
}
.row.has-bg .bg.bg--h-left img {
  left: 0;
  right: auto;
}
.row.has-bg .bg.bg--h-center img {
  left: -3000px;
  right: -3000px;
}
.row.has-bg .bg.bg--h-right img {
  left: auto;
  right: 0;
}
.row.has-bg > .bg {
  position: absolute;
}

.bg--horizon-40 {
  background-color: #f8fafb;
  color: #333;
}

.bg--horizon {
  background-color: #eef3f6;
  color: #333;
}

.bg--aluminum-80 {
  background-color: #e4e9ec;
  color: #333;
}

.bg--aluminum {
  background-color: #dde3e7;
  color: #333;
}

.bg--gray-20 {
  background-color: #d6d6d6;
  color: #333;
}

.bg--gray-40 {
  background-color: #adadad;
  color: #333;
}

.bg--blue-20 {
  background-color: #d1e6f9;
  color: #333;
}

.bg--blue-40 {
  background-color: #a2cdf4;
  color: #333;
}

/*New*/
.border {
  border-width: 1px;
}
.border.border-a {
  border-style: solid;
}
.border.border-h {
  border-left-style: solid;
  border-right-style: solid;
}
.border.border-v {
  border-top-style: solid;
  border-bottom-style: solid;
}
.border.border-top {
  border-top-style: solid;
}
.border.border-bottom {
  border-bottom-style: solid;
}
.border.border-right {
  border-right-style: solid;
}
.border.border-left {
  border-left-style: solid;
}
.border.border--light-gray {
  border-color: #d6d6d6;
}
.border.border--mid-gray {
  border-color: #adadad;
}
.border.border--dark-gray {
  border-color: #5c5c5c;
}
.border.border--white {
  border-color: #fff;
}
.border.border--horizon-40 {
  border-color: #f8fafb;
}
.border.border--horizon {
  border-color: #eef3f6;
}
.border.border--aluminum-80 {
  border-color: #e4e9ec;
}
.border.border--aluminum {
  border-color: #dde3e7;
}
.border.border--gray-20 {
  border-color: #d6d6d6;
}
.border.border--gray-40 {
  border-color: #adadad;
}
.border.border--blue-20 {
  border-color: #d1e6f9;
}
.border.border--blue-40 {
  border-color: #a2cdf4;
}
.border.border--shadow-small {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
}

/*New*/
.button-group.button-group-align--left {
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.button-group.button-group-align--center {
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media screen and (max-width: 479px) {
  .component-button,
  div.align-right,
  div.align-center,
  sdx-button,
  sdx-button.sc-sdx-button-h {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .early_full_width .component-button {
    width: 100%;
  }
  .early_full_width .component-button button.turquoise,
  .early_full_width .component-button button.white {
    display: flex;
    justify-content: center;
  }
}
button.turquoise,
button.white {
  padding: 11px 23px;
  border: 2px solid #fff;
}
button.turquoise a,
button.white a {
  border: 0;
  text-decoration: none;
}
button.turquoise .button-icon,
button.white .button-icon {
  margin-right: 8px;
}
@media screen and (max-width: 479px) {
  button.turquoise,
  button.white {
    display: flex;
    justify-content: center;
  }
}

button.turquoise {
  background-color: #0eaba9;
  color: #fff;
}
button.turquoise a {
  color: #fff;
}

button.white {
  background-color: #eef3f6;
  color: #086adb;
}
button.white a {
  color: #086adb;
}

.align-center {
  text-align: center;
}

/*New*/
.modImage__link, .modImage__link:hover {
  border: 0;
}

.modImage__image {
  width: 100%;
  height: auto;
}

.cmp-image__image {
  height: auto;
}

.cmp-image__link, .cmp-image__link:hover {
  border: 0;
}
.cmp-image__link.modal-zoom .icon {
  position: absolute;
  bottom: 8px;
  right: 8px;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  background-color: #086adb;
}
.cmp-image__link.modal-zoom .icon::before {
  position: relative;
  top: 9px;
}
.cmp-image__link.modal-zoom:hover .icon {
  background-color: #0048cf;
}
@media screen and (max-width: 767px) {
  .cmp-image__link.modal-zoom {
    pointer-events: none;
  }
}

.image-circle img {
  border-radius: 50%;
}

.image-round-corners {
  border-radius: 12px;
}
.image-round-corners img {
  border-radius: 12px;
}

.image.aem-GridColumn--default--6 .fixed-image-height {
  overflow: hidden;
  display: flex !important;
  justify-content: center;
}
.image.aem-GridColumn--default--6 .fixed-image-height img {
  width: auto;
  height: 200px;
}
@media only screen and (min-width: 480px) {
  .image.aem-GridColumn--default--6 .fixed-image-height img {
    height: 312px;
  }
}
@media only screen and (min-width: 768px) {
  .image.aem-GridColumn--default--6 .fixed-image-height img {
    height: 296px;
  }
}
@media only screen and (min-width: 1024px) {
  .image.aem-GridColumn--default--6 .fixed-image-height img {
    height: 384px;
  }
}
@media only screen and (min-width: 1280px) {
  .image.aem-GridColumn--default--6 .fixed-image-height img {
    height: 440px;
  }
}

.image.image-align-center,
.aem-Grid > .aem-GridColumn.image.image-align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.image.image-align-left,
.aem-Grid > .aem-GridColumn.image.image-align-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.image.image-align-right,
.aem-Grid > .aem-GridColumn.image.image-align-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .image.image-align-center-sm,
  .aem-Grid > .aem-GridColumn.image.image-align-center-sm {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 767px) {
  .image.image-align-left-sm,
  .aem-Grid > .aem-GridColumn.image.image-align-left-sm {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
@media screen and (max-width: 767px) {
  .image.image-align-right-sm,
  .aem-Grid > .aem-GridColumn.image.image-align-right-sm {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.card .card__text {
  white-space: pre-wrap;
}

figure.table {
  margin: 0;
}

.scsh-offers p {
  font-size: inherit;
  font-family: inherit;
  letter-spacing: inherit;
  line-height: inherit;
}
.scsh-offers .linklist,
.scsh-offers .modPromoBadge {
  font-size: 15px;
}

.modHeader a {
  border-bottom-color: transparent;
}

.modHeader .top-navigation ul.scsh-icon-menu > li.burger a {
  position: inherit;
}

.modPromoBadge {
  z-index: 2;
  font-size: 12px;
}
.modPromoBadge span.chevron {
  font-family: "SwisscomIcons" !important;
  display: none;
  background: none;
  margin: 0 0 0 20px;
}
.modPromoBadge.medium {
  font-size: 15px;
}
.modPromoBadge.left {
  float: left;
  margin: 0 0 0 -12px;
  left: 5px;
}
.modPromoBadge.left .ribbon {
  padding: 0 10px;
}
.modPromoBadge.left .ribbon:before {
  display: none;
}
.modPromoBadge.left .ribbon:after {
  content: "";
  border-right-color: transparent !important;
  border-style: solid;
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -0.5em;
  width: 1px;
  border-bottom-width: 1em;
  border-top-width: 1em;
  border-left-width: 0;
  border-right-width: 0.5em;
}
.modPromoBadge .ribbon {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  text-align: center;
  line-height: 2em;
  min-height: 2em;
  position: relative;
  padding: 0;
  color: #fff;
  background-color: #801a99;
}

.page:not(.home-page) > main > .root > .aem-Grid > .responsivegrid,
.page:not(.home-page) > .root > .aem-Grid > .responsivegrid {
  max-width: 1380px;
  margin: 0px auto;
  float: none;
  padding-left: 18px;
  padding-right: 18px;
}
@media screen and (min-width: 768px) {
  .page:not(.home-page) > main > .root > .aem-Grid > .responsivegrid,
  .page:not(.home-page) > .root > .aem-Grid > .responsivegrid {
    padding-left: 12px;
    padding-right: 12px;
  }
  .page:not(.home-page) > main > .root > .aem-Grid > .responsivegrid .aem-GridColumn,
  .page:not(.home-page) > .root > .aem-Grid > .responsivegrid .aem-GridColumn {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media screen and (min-width: 1024px) {
  .page:not(.home-page) > main > .root > .aem-Grid > .responsivegrid,
  .page:not(.home-page) > .root > .aem-Grid > .responsivegrid {
    padding-left: 26px;
    padding-right: 26px;
  }
  .page:not(.home-page) > main > .root > .aem-Grid > .responsivegrid .aem-GridColumn,
  .page:not(.home-page) > .root > .aem-Grid > .responsivegrid .aem-GridColumn {
    padding-left: 12px;
    padding-right: 12px;
  }
}
@media screen and (min-width: 1280px) {
  .page:not(.home-page) > main > .root > .aem-Grid > .responsivegrid,
  .page:not(.home-page) > .root > .aem-Grid > .responsivegrid {
    padding-left: 28px;
    padding-right: 28px;
  }
}
@media screen and (min-width: 1440px) {
  .page:not(.home-page) > main > .root > .aem-Grid > .responsivegrid,
  .page:not(.home-page) > .root > .aem-Grid > .responsivegrid {
    padding-left: 0;
    padding-right: 0;
  }
}

.xf-web-container > .xp-fragment-container > .root.responsivegrid {
  max-width: 1380px;
  margin: 0px auto;
  float: none;
  padding-left: 18px;
  padding-right: 18px;
}
.xf-web-container > .xp-fragment-container > .root.responsivegrid .aem-GridColumn {
  padding-bottom: 0;
}
@media screen and (min-width: 768px) {
  .xf-web-container > .xp-fragment-container > .root.responsivegrid {
    padding-left: 12px;
    padding-right: 12px;
  }
  .xf-web-container > .xp-fragment-container > .root.responsivegrid .aem-GridColumn {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media screen and (min-width: 1024px) {
  .xf-web-container > .xp-fragment-container > .root.responsivegrid {
    padding-left: 26px;
    padding-right: 26px;
  }
  .xf-web-container > .xp-fragment-container > .root.responsivegrid .aem-GridColumn {
    padding-left: 12px;
    padding-right: 12px;
  }
}
@media screen and (min-width: 1280px) {
  .xf-web-container > .xp-fragment-container > .root.responsivegrid {
    padding-left: 28px;
    padding-right: 28px;
  }
}
@media screen and (min-width: 1440px) {
  .xf-web-container > .xp-fragment-container > .root.responsivegrid {
    padding-left: 0;
    padding-right: 0;
  }
}

.home-page > main > .root > .aem-Grid > .responsivegrid,
.home-page > .root > .aem-Grid > .responsivegrid {
  max-width: 1920px;
  margin: 0px auto;
  float: none;
}

.home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .experiencefragment.aem-GridColumn--default--4:nth-of-type(3n + 1), .home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .experiencefragment.aem-GridColumn--default--6:nth-of-type(odd), .home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .teaser.aem-GridColumn--default--4:nth-of-type(3n + 1), .home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .teaser.aem-GridColumn--default--6:nth-child(odd) {
  padding-left: 0;
}
@media only screen and (max-width: 480px) {
  .home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .experiencefragment.aem-GridColumn--default--4:nth-of-type(3n + 1), .home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .experiencefragment.aem-GridColumn--default--6:nth-of-type(odd), .home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .teaser.aem-GridColumn--default--4:nth-of-type(3n + 1), .home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .teaser.aem-GridColumn--default--6:nth-child(odd) {
    padding-right: 0;
  }
}

.home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .experiencefragment.aem-GridColumn--default--6:nth-of-type(even), .home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .experiencefragment.aem-GridColumn--default--4:nth-of-type(3n), .home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .teaser.aem-GridColumn--default--4:nth-of-type(3n), .home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .teaser.aem-GridColumn--default--6:nth-child(even) {
  padding-right: 0;
}
@media only screen and (max-width: 480px) {
  .home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .experiencefragment.aem-GridColumn--default--6:nth-of-type(even), .home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .experiencefragment.aem-GridColumn--default--4:nth-of-type(3n), .home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .teaser.aem-GridColumn--default--4:nth-of-type(3n), .home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .teaser.aem-GridColumn--default--6:nth-child(even) {
    padding-left: 0;
  }
}

.home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .component-container.aem-GridColumn--default--12, .home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .experiencefragment.aem-GridColumn--default--12, .home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .teaser.aem-GridColumn--default--12 {
  padding-left: 0;
  padding-right: 0;
}

@media only screen and (max-width: 480px) {
  .home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .experiencefragment.aem-GridColumn--sm--12, .home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .teaser.aem-GridColumn--sm--12 {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 768px) {
  .home-page > .root > .aem-Grid > .responsivegrid .aem-Grid.aem-Grid--default--12.aem-Grid--sm--12.aem-Grid--xs--12 .teaser:not(.aem-GridColumn--sm--6):first-of-type {
    margin-top: -8px;
  }
}

@media only screen and (max-width: 1920px) {
  .home-page > .root > .aem-Grid > .responsivegrid .aem-Grid.aem-Grid--default--12.aem-Grid--sm--12.aem-Grid--xs--12 .teaser:first-of-type .sc-teaserNavigation {
    margin-left: 4px;
  }
}
@media only screen and (max-width: 1920px) {
  .home-page > .root > .aem-Grid > .responsivegrid .aem-Grid.aem-Grid--default--12.aem-Grid--sm--12.aem-Grid--xs--12 .teaser:last-of-type .sc-teaserNavigation {
    margin-right: 4px;
  }
}

@media only screen and (max-width: 480px) {
  .home-page > .root > .aem-Grid > .responsivegrid .aem-Grid.aem-Grid--default--12.aem-Grid--sm--12.aem-Grid--xs--12 .teaser:first-of-type .sc-teaserNavigation, .home-page > .root > .aem-Grid > .responsivegrid .aem-Grid.aem-Grid--default--12.aem-Grid--sm--12.aem-Grid--xs--12 .teaser:last-of-type .sc-teaserNavigation {
    margin-left: 24px;
    margin-right: 24px;
  }
}

@media only screen and (max-width: 480px) {
  .home-page > .root > .aem-Grid > .responsivegrid .aem-Grid.aem-Grid--default--12.aem-Grid--sm--12.aem-Grid--xs--12 .teaser:not(:first-of-type):not(:last-of-type) .sc-teaserNavigation {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .experiencefragment .cmp-experiencefragment {
  margin-top: 0;
  margin-bottom: 0;
}
.home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .experiencefragment .cmp-experiencefragment .aem-GridColumn {
  margin-top: 0;
  margin-bottom: 0;
}