@use 'lib/styles/defs/breakpoints';
@import 'lib/styles/defs/mixins';
@import 'whitespace';
@include whitespace(height);

:root,
.has-light-background {
	--text-color: var(--sdx-color-gray);
	--headline-color: var(--sdx-color-sc-navy);
	--int-color: var(--sdx-color-int-blue);
	--int-color--active: var(--sdx-color-int-blue--active);
	color: var(--text-color);
}

.has-dark-background {
	--text-color: var(--sdx-color-sc-white);
	--headline-color: var(--sdx-color-sc-white);
	--int-color: var(--sdx-color-sc-white);
	--int-color--active: var(--sdx-color-sc-white);
	color: var(--text-color);
}

html {
	scroll-behavior: smooth;
	quotes: '«' '»' '‹' '›';

	&:lang(de) {
		quotes: '«' '»' '‹' '›' '„' '“' '‚' '‘';
	}

	&:lang(en) {
		quotes: '“' '”' '‘' '’';
	}
}

body {
	overflow-x: hidden; // Solve the x scroll bar when using fullbleed container
	overflow-y: scroll; // Always show vertical scroll bars on windows to prevent layout shifting
}
body.xf-web-container {
	overflow-y: auto; // Don't force scroll bars on Experience Fragment "pages" (for iFrames)
}

.pagemetadata {
	display: none;
}

.reset-paddings {
	&.padding-top-1,
	&.padding-top-2,
	&.padding-top-3,
	&.padding-top-4 {
		padding-top: 0 !important; // reset sdx padding style
	}

	&.padding-bottom-1,
	&.padding-bottom-2,
	&.padding-bottom-3,
	&.padding-bottom-4 {
		padding-bottom: 0 !important; // reset sdx padding style
	}

	&.padding-left-1,
	&.padding-left-2,
	&.padding-left-3,
	&.padding-left-4 {
		@include reset-horizontal-paddings(left); // ensure default aem-grid padding values
	}

	&.padding-right-1,
	&.padding-right-2,
	&.padding-right-3,
	&.padding-right-4 {
		@include reset-horizontal-paddings(right); // ensure default aem-grid padding values
	}
}

table.table-text-wrap {
	white-space: normal;
	word-break: break-word;
}

body[data-wcm-mode='DISABLED'] .aem-functional-div {
	display: contents;
}

$overrides: (
	'margin-top': 8px,
	'margin-bottom': 8px,
	'margin-left': 0,
	'margin-right': 0,
	'padding-top': 0,
	'padding-bottom': 0,
	'padding-left': 4px,
	'padding-right': 4px,
);

@media screen and (max-width: breakpoints.$breakpoint-max-sm) {
	@each $property, $value in $overrides {
		.#{$property}-override,
		.reset-paddings.#{$property}-override {
			#{$property}: $value !important;
		}
	}
}
