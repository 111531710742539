@use 'sass:math';

.scsh-offers {
	p {
		font-size: inherit;
		font-family: inherit;
		letter-spacing: inherit;
		line-height: inherit;
	}

	.linklist,
	.modPromoBadge {
		font-size: 15px;
	}
}

.modHeader a {
	border-bottom-color: transparent;
}

.modHeader .top-navigation ul.scsh-icon-menu > li.burger a {
	position: inherit;
}

.modPromoBadge {
	$sizeToFontSizeRatio: 2em;
	$sizeToRibbonEdges: math.div($sizeToFontSizeRatio, 2);
	$sizeToRibbonOutdent: math.div($sizeToFontSizeRatio, 4);

	$cornerSize: 6px;

	z-index: 2;
	font-size: 12px;

	span.chevron {
		font-family: 'SwisscomIcons' !important;
		display: none;
		background: none;
		margin: 0 0 0 20px;
	}

	&.medium {
		font-size: 15px;
	}

	&.left {
		float: left;
		margin: 0 0 0 (-2 * $cornerSize);
		left: 5px;

		.ribbon {
			padding: 0 10px;

			&:before {
				display: none;
			}

			&:after {
				content: '';
				border-right-color: transparent !important;
				border-style: solid;
				position: absolute;
				z-index: 1;
				bottom: 0;
				right: -$sizeToRibbonOutdent;
				width: 1px;
				border-bottom-width: $sizeToRibbonEdges;
				border-top-width: $sizeToRibbonEdges;
				border-left-width: 0;
				border-right-width: $sizeToRibbonOutdent;
			}
		}
	}

	.ribbon {
		box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
		text-align: center;
		line-height: $sizeToFontSizeRatio;
		min-height: $sizeToFontSizeRatio;
		position: relative;
		padding: 0;
		color: #fff;
		background-color: #801a99;
	}
}
