@use '~@swisscom/sdx/stylesheets/sdx/variables/colors';
@use 'lib/styles/defs/breakpoints';

// swisscom/sdx/components/basic/image (deprecated)
.modImage__link {
	&,
	&:hover {
		border: 0;
	}
}
.modImage__image {
	width: 100%;
	height: auto;
}

.cmp-image__image {
	height: auto;
}

// swisscom/components/content/image
.cmp-image__link {
	&,
	&:hover {
		border: 0;
	}
	&.modal-zoom {
		.icon {
			position: absolute;
			bottom: 8px;
			right: 8px;
			width: 40px;
			height: 40px;
			text-align: center;
			border-radius: 50%;
			background-color: colors.$color-int-blue;

			&::before {
				position: relative;
				top: 9px;
			}
		}

		&:hover {
			.icon {
				background-color: colors.$color-int-blue--active;
			}
		}

		@media screen and (max-width: breakpoints.$breakpoint-max-sm) {
			pointer-events: none;
		}
	}
}

.image-circle {
	img {
		border-radius: 50%;
	}
}

.image-round-corners {
	border-radius: 12px;
	img {
		border-radius: 12px;
	}
}

.fixed-image-height {
	.image.aem-GridColumn--default--6 & {
		overflow: hidden;
		display: flex !important;
		justify-content: center;

		img {
			width: auto;
			height: 200px;
			@media only screen and (min-width: breakpoints.$breakpoint-sm) {
				height: 312px;
			}
			@media only screen and (min-width: breakpoints.$breakpoint-md) {
				height: 296px;
			}
			@media only screen and (min-width: breakpoints.$breakpoint-lg) {
				height: 384px;
			}
			@media only screen and (min-width: breakpoints.$breakpoint-xl) {
				height: 440px;
			}
		}
	}
}

.image,
.aem-Grid > .aem-GridColumn.image {
	&.image-align-center {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&.image-align-left {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	&.image-align-right {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	&.image-align-center-sm {
		@media screen and (max-width: breakpoints.$breakpoint-max-sm) {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	&.image-align-left-sm {
		@media screen and (max-width: breakpoints.$breakpoint-max-sm) {
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}
	}
	&.image-align-right-sm {
		@media screen and (max-width: breakpoints.$breakpoint-max-sm) {
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}
	}
}
