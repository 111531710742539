@use 'lib/styles/defs/breakpoints';
@use '~@swisscom/sdx/stylesheets/sdx/variables/colors';

.button-group {
	&.button-group-align--left {
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	&.button-group-align--center {
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
}

@media screen and (max-width: breakpoints.$breakpoint-max-xs) {
	.component-button,
	div.align-right,
	div.align-center,
	sdx-button,
	sdx-button.sc-sdx-button-h {
		width: 100%;
	}
}

@media screen and (max-width: breakpoints.$breakpoint-max-sm) {
	.early_full_width {
		.component-button {
			width: 100%;
			button.turquoise,
			button.white {
				display: flex;
				justify-content: center;
			}
		}
	}
}

button.turquoise,
button.white {
	padding: 11px 23px;
	border: 2px solid colors.$color-white;
	a {
		border: 0;
		text-decoration: none;
	}
	.button-icon {
		margin-right: 8px;
	}
	@media screen and (max-width: breakpoints.$breakpoint-max-xs) {
		& {
			display: flex;
			justify-content: center;
		}
	}
}

button.turquoise {
	background-color: colors.$color-turquoise;
	color: colors.$color-white;
	a {
		color: colors.$color-white;
	}
}

button.white {
	background-color: colors.$color-horizon;
	color: colors.$color-blue;
	a {
		color: colors.$color-blue;
	}
}

.align-center {
	text-align: center;
}
