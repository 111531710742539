.home-page > main > .root > .aem-Grid > .responsivegrid,
.home-page > .root > .aem-Grid > .responsivegrid {
	max-width: 1920px;
	margin: 0px auto;
	float: none;
}

%padding-left {
	padding-left: 0;
	@media only screen and (max-width: 480px) {
		padding-right: 0;
	}
}
%padding-right {
	padding-right: 0;
	@media only screen and (max-width: 480px) {
		padding-left: 0;
	}
}
%padding-zero {
	padding-left: 0;
	padding-right: 0;
}

%padding-zero-sm {
	@media only screen and (max-width: 480px) {
		padding-left: 0;
		padding-right: 0;
	}
}

%margin-4-teaser-md {
	@media only screen and (max-width: 768px) {
		margin-top: -8px;
	}
}

.home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .teaser.aem-GridColumn--default--6:nth-child(odd) {
	@extend %padding-left;
}

.home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .teaser.aem-GridColumn--default--6:nth-child(even) {
	@extend %padding-right;
}

.home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .teaser.aem-GridColumn--default--4:nth-of-type(3n + 1) {
	@extend %padding-left;
}
.home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .teaser.aem-GridColumn--default--4:nth-of-type(3n) {
	@extend %padding-right;
}
.home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .teaser.aem-GridColumn--default--12 {
	@extend %padding-zero;
}
.home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .teaser.aem-GridColumn--sm--12 {
	@extend %padding-zero-sm;
}

.home-page
	> .root
	> .aem-Grid
	> .responsivegrid
	.aem-Grid.aem-Grid--default--12.aem-Grid--sm--12.aem-Grid--xs--12
	.teaser:not(.aem-GridColumn--sm--6):first-of-type {
	@extend %margin-4-teaser-md;
}

.home-page > .root > .aem-Grid > .responsivegrid .aem-Grid.aem-Grid--default--12.aem-Grid--sm--12.aem-Grid--xs--12 .teaser {
	&:first-of-type .sc-teaserNavigation {
		@media only screen and (max-width: 1920px) {
			margin-left: 4px;
		}
	}
	&:last-of-type .sc-teaserNavigation {
		@media only screen and (max-width: 1920px) {
			margin-right: 4px;
		}
	}
}

.home-page > .root > .aem-Grid > .responsivegrid .aem-Grid.aem-Grid--default--12.aem-Grid--sm--12.aem-Grid--xs--12 .teaser {
	&:first-of-type .sc-teaserNavigation,
	&:last-of-type .sc-teaserNavigation {
		@media only screen and (max-width: 480px) {
			margin-left: 24px;
			margin-right: 24px;
		}
	}
}

.home-page
	> .root
	> .aem-Grid
	> .responsivegrid
	.aem-Grid.aem-Grid--default--12.aem-Grid--sm--12.aem-Grid--xs--12
	.teaser:not(:first-of-type):not(:last-of-type)
	.sc-teaserNavigation {
	@media only screen and (max-width: 480px) {
		margin-left: 20px;
		margin-right: 20px;
	}
}

.home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .experiencefragment.aem-GridColumn--default--6:nth-of-type(odd) {
	@extend %padding-left;
}

.home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .experiencefragment.aem-GridColumn--default--4:nth-of-type(3n + 1) {
	@extend %padding-left;
}
.home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .experiencefragment.aem-GridColumn--default--4:nth-of-type(3n) {
	@extend %padding-right;
}

.home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .experiencefragment.aem-GridColumn--default--6:nth-of-type(even) {
	@extend %padding-right;
}

.home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .experiencefragment.aem-GridColumn--default--12 {
	@extend %padding-zero;
}

.home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .experiencefragment.aem-GridColumn--sm--12 {
	@extend %padding-zero-sm;
}

.home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .component-container.aem-GridColumn--default--12 {
	@extend %padding-zero;
}

.home-page > .root > .aem-Grid > .responsivegrid .aem-Grid .experiencefragment .cmp-experiencefragment {
	margin-top: 0;
	margin-bottom: 0;
	.aem-GridColumn {
		margin-top: 0;
		margin-bottom: 0;
	}
}
