@use '~@swisscom/sdx/stylesheets/sdx/variables/colors';

.row.has-bg {
	position: relative;

	.bg {
		position: relative;
		min-width: 100%;
		min-height: 100%;
		left: 0;
		right: 0;
		overflow: hidden;

		&.full-width {
			@media (min-width: 0) {
				left: 50%;
				right: 50%;
			}

			@media (min-width: 768px) {
				left: calc(50% - 10px);
				right: calc(50% - 10px);
			}
		}

		&.bg--image-mode-natural {
			img {
				// position center top by default
				position: absolute;
				margin: auto;
			}
		}

		&.bg--image-mode-cover {
			img {
				min-width: 100%;
				min-height: 100%;
				width: auto;
				height: auto;
				position: absolute;
				margin: auto;
			}
		}

		&.bg--v-top {
			img {
				top: 0;
				bottom: auto;
			}
		}
		&.bg--v-middle {
			img {
				top: -3000px;
				bottom: -3000px;
			}
		}
		&.bg--v-bottom {
			img {
				top: auto;
				bottom: 0;
			}
		}
		&.bg--h-left {
			img {
				left: 0;
				right: auto;
			}
		}
		&.bg--h-center {
			img {
				left: -3000px;
				right: -3000px;
			}
		}
		&.bg--h-right {
			img {
				left: auto;
				right: 0;
			}
		}
	}

	> .bg {
		position: absolute;
	}
}

// Additional sdx background colors not present in sdx.css
.bg--horizon-40 {
	background-color: colors.$color-horizon-tint-2;
	color: colors.$color-gray;
}
.bg--horizon {
	background-color: colors.$color-horizon;
	color: colors.$color-gray;
}
.bg--aluminum-80 {
	background-color: colors.$color-aluminum-tint-2;
	color: colors.$color-gray;
}
.bg--aluminum {
	background-color: colors.$color-aluminum;
	color: colors.$color-gray;
}
.bg--gray-20 {
	background-color: colors.$color-gray-tint-8;
	color: colors.$color-gray;
}
.bg--gray-40 {
	background-color: colors.$color-gray-tint-6;
	color: colors.$color-gray;
}
.bg--blue-20 {
	background-color: colors.$color-blue-tint-3;
	color: colors.$color-gray;
}
.bg--blue-40 {
	background-color: colors.$color-blue-tint-2;
	color: colors.$color-gray;
}
