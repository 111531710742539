// Custom styles for additional aspect ratio options.

.col-xs-has-aspect-ratio:before {
	content: '';
	width: 1px;
	margin-left: -1px;
	float: left;
	height: 0;
}

.col-xs-has-aspect-ratio:after {
	content: '';
	display: table;
	clear: both;
}

.col-xs-has-aspect-ratio > div {
	width: 100%;
}
