@use '~@swisscom/sdx/stylesheets/sdx/variables/colors';

.border {
	border-width: 1px;

	&.border-a {
		border-style: solid;
	}
	&.border-h {
		border-left-style: solid;
		border-right-style: solid;
	}
	&.border-v {
		border-top-style: solid;
		border-bottom-style: solid;
	}
	&.border-top {
		border-top-style: solid;
	}
	&.border-bottom {
		border-bottom-style: solid;
	}
	&.border-right {
		border-right-style: solid;
	}
	&.border-left {
		border-left-style: solid;
	}

	&.border--light-gray {
		border-color: colors.$color-gray-tint-8;
	}
	&.border--mid-gray {
		border-color: colors.$color-gray-tint-6;
	}
	&.border--dark-gray {
		border-color: colors.$color-gray-tint-3;
	}

	// Additional sdx border colors not present in the sdx.css
	&.border--white {
		border-color: colors.$color-white;
	}
	&.border--horizon-40 {
		border-color: colors.$color-horizon-tint-2;
	}
	&.border--horizon {
		border-color: colors.$color-horizon;
	}
	&.border--aluminum-80 {
		border-color: colors.$color-aluminum-tint-2;
	}
	&.border--aluminum {
		border-color: colors.$color-aluminum;
	}
	&.border--gray-20 {
		border-color: colors.$color-gray-tint-8;
	}
	&.border--gray-40 {
		border-color: colors.$color-gray-tint-6;
	}
	&.border--blue-20 {
		border-color: colors.$color-blue-tint-3;
	}
	&.border--blue-40 {
		border-color: colors.$color-blue-tint-2;
	}

	&.border--shadow-small {
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
	}
}
